import React from "react";
import "./landing.css";
import LandingPageSectiontwo from "./sections/LandingPageSectiontwo";
import LandingPageSectionThree from "./sections/LandingPageSectionThree";
import LandingPageContactSection from "./sections/LandingPageContactSection";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <>
      <div className="firstsectionContainer">
        <div className="container d-flex justify-content-center">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="col-12 firstSectionHeading">
                  Finger Licking taste ! Order Karein Aaj he
                </div>
                <div className="col-12 firstSectionsubHeading mt-3">
                  order karein hamare yahan ke 70+ food options se aur delivery
                  paayein seedha aapke ghar pe
                </div>
                <div className="col-sm-6 col-md-12 col-lg-12 justify-content-center mobilecssforordernow">
                  <Link to="/all-products/all">
                    <button className="firstSectionOrderNowButton mt-4">
                      Order Now{" "}
                      <span>
                        <img
                          src="/landingpageicons/fork.png"
                          height={30}
                          width={30}
                        ></img>
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-12 col-lg-12 mt-sm-2 mt-lg-4">
                  <div className="deliverytext mt-2 d-flex justify-content-center">
                    <span>
                      <img
                        src="/landingpageicons/delivery-bike.png"
                        height={30}
                        width={30}
                      ></img>
                    </span>{" "}
                    30 Minutes Devlivery ....
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img
                src="/landingpageicons/firstsection.jpg"
                className="firstsectionimage"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <LandingPageSectionThree />
      <LandingPageSectiontwo />
      <LandingPageContactSection />
    </>
  );
};

export default LandingPage;
